<template>
    <div id="photo-gallery-wrapper">
        <swiper v-if="galleryContent.length >= 1" id="collection" class="swiper" :options="swiperOption">
            <swiper-slide v-for="image in galleryContent" :key="image.name" class="swiper-slide">
                <img v-if="image.formats.thumbnail.width >= image.formats.thumbnail.height" class="image-landscape swiper-lazy" :src="api_url + image.formats.medium.url" :alt="'nsfs-' + image.caption">
                <img v-else-if="image.formats.thumbnail.width <= image.formats.thumbnail.height" class="image-portrait swiper-lazy" :src="api_url + image.formats.medium.url" :alt="'nsfs-' + image.caption">
                <div class="swiper-lazy-preloader" />
                <figcaption v-if="image.caption">
                    {{ image.caption }}
                </figcaption>
            </swiper-slide>
            <div v-if="galleryContent.length > 1" slot="pagination" class="swiper-pagination metatext" />
    
            <div v-if="galleryContent.length > 1" slot="button-prev" class="swiper-button-prev-unique gallery--left-side" />
            <div v-if="galleryContent.length > 1" slot="button-next" class="swiper-button-next-unique gallery--right-side" />
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        galleryContent: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            swiperOption: {
                // autoHeight: true, //enable auto height
                slidesPerView: 2,
                spaceBetween: 25,
                resistanceRatio: 0,
                preloadImages: true,
                loop: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 1
                },
                navigation: {
                    nextEl: '.swiper-button-next-unique',
                    prevEl: '.swiper-button-prev-unique'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
                renderFraction: function(currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' +
                        ' of ' +
                        '<span class="' + totalClass + '"></span>';
                }
            }
        }
    }
}
</script>