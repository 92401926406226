<template>
    <div v-if="gridContent" class="image-grid">
        <div v-for="item in gridContent" :key="item.id" class="image-grid--item">
<!-- <div class="image"> -->
                
            <!-- </div> -->

            <div id="gallery">
                <swiper id="collection" ref="mySwiper" class="swiper" :options="swiperOption">
                    <swiper-slide class="swiper-slide">
                        <img v-if="item.thumbnail && item.thumbnail.formats.small" :src="apiUrl + item.thumbnail.formats.small.url" :alt="item.title_no">
                        <img v-else :src="apiUrl + item.thumbnail.formats.thumbnail.url" :alt="item.title_no">
                        <img v-if="item.photo_cover" :src="apiUrl + item.photo_cover.formats.small.url" :alt="item.title_no">      
                        <!-- <figcaption>{{ item.thumbnail.caption }}</figcaption>   -->
                    </swiper-slide>
                    <swiper-slide v-for="image in item.photo_gallery" :key="image.name" class="swiper-slide">
                        <img v-if="image.formats.thumbnail.width >= image.formats.thumbnail.height" class="image-landscape swiper-lazy" :src="apiUrl + image.formats.small.url">
                        <img v-else-if="image.formats.thumbnail.width <= image.formats.thumbnail.height" class="image-portrait swiper-lazy" :src="apiUrl + image.formats.small.url">
                        <!-- <figcaption>{{ image.caption }}</figcaption>   -->
                        <!-- <div class="swiper-lazy-preloader"></div> -->
                    </swiper-slide>
        
                    <div v-if="item.photo_gallery.length > 1" slot="button-prev" class="swiper-button-prev-unique gallery--left-side" />
                    <div v-if="item.photo_gallery.length > 1" slot="button-next" class="swiper-button-next-unique gallery--right-side" />
                    <div v-if="item.photo_gallery.length > 1" slot="pagination" class="swiper-pagination metatext" />
                </swiper>
            </div>
    
    
            <div class="text">
                <span class="title-default">{{ item.title_no }}</span>
                <div class="description">
                    <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="item.contentShort_no" class="metatext markdown" />
                    <MarkedParser v-else :marked-content="item.contentShort_en" class="metatext markdown" />
                </div>
            </div>
    
            <SlidePanel ref="panel" />
        </div>
    </div>
</template>

<script>
import SlidePanel from '@/components/SlidePanel'
import MarkedParser from '@/components/MarkedParser'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        MarkedParser,
        SlidePanel,
        Swiper,
        SwiperSlide,
    },
    props: {
        gridContent: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            apiUrl: process.env.VUE_APP_STRAPI_API_URL,
            swiperOption: {
                spaceBetween: 25,
                slidesPerView: 1,
                resistanceRatio: 0,
                // preloadImages: true,
                // autoplay: true,
                loop: false,
                // lazy: {
                //     loadPrevNext: true,
                //     loadPrevNextAmount: 1
                // },
                navigation: {
                    nextEl: '.swiper-button-next-unique',
                    prevEl: '.swiper-button-prev-unique'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
            }
        }
    },
    updated() {
        const swiperWrapper = document.querySelector('.swiper');
        if (swiperWrapper) {
            swiperWrapper.swiper.init();
        }
    },
}
</script>

