<template>
    <section v-if="cafe" id="single-page">
        <div class="defaultcontent-grid">
            <PhotoCover v-if="cafe.photo_cover" :photo-cover="cafe.photo_cover" />
            <BaseGallery v-if="cafe.photo_gallery" :gallery-content="cafe.photo_gallery" />
    
            <div class="singlepage-grid-primary">
                <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="cafe.content_no" class="markdown" />
                <MarkedParser v-else :marked-content="cafe.content_en" class="markdown" />
    
                <!-- <MenuComponent v-if="cafe.foodmenu && cafe.drinks" :menuContent="cafe.foodmenu"></MenuComponent>
                    <MenuComponent v-if="cafe.foodmenu && cafe.drinks" :menuContent="cafe.drinks"></MenuComponent>
                    <div v-else id="menu">
                        <div class="menusection-placeholder">
                            <h2>{{ $t('cafe.comingSoon')}}</h2>
                        </div>
                    </div> -->
            </div>
    
            <div class="singlepage-grid-secondary">
                <figure>
                    <img src="../assets/images/nitja-kafelogo-500px.svg" alt="Nitja kafe logo">
                </figure>
    
                <ul id="address">
                    <p>{{ cafe.name }}</p>
                    <p>{{ cafe.streetname }}</p>
                    <p>{{ cafe.zipcode }}, {{ cafe.city }}</p>
                    <p v-if="$i18n.locale === 'en'">
                        {{ cafe.country_en }}
                    </p>
                    <p v-else>
                        {{ cafe.country_no }}
                    </p>
                </ul>
    
                <div id="contact">
                    <a v-if="cafe.mail" :href="`mailto:${cafe.mail}`">
                        <span class="reversed">{{ reverseMessage(cafe.mail) }}</span>
                      </a>
                </div>
    
                <ul id="socials">
                    <li v-if="cafe.facebook">
                        <a :href="cafe.facebook | cleanUrl" target="_blank" rel="noreferrer noopener">
                          <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg></span>
                        </a>
                    </li>
                    <li v-if="cafe.instagram">
                        <a :href="cafe.instagram | cleanUrl" target="_blank" rel="noreferrer noopener">
                          <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="4" y="4" width="16" height="16" rx="4" /><circle cx="12" cy="12" r="3" /><line x1="16.5" y1="7.5" x2="16.5" y2="7.501" /></svg></span>
                        </a>
                    </li>
                    <li v-if="cafe.twitter">
                        <a :href="cafe.twitter | cleanUrl" target="_blank" rel="noreferrer noopener">
                          <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitter" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z" /></svg></span>
                        </a>
                    </li>
                </ul>
    
                <ul id="timetable" class="timetable">
                    <li v-for="day in cafe.openinghours" :key="day.day_en">
                        <span v-if="$i18n.locale === 'en'">{{ day.day_en }}</span>
                        <span v-else>{{ day.day_no }}</span>
                        <span v-if="day.opening">{{ day.opening | formatTime }}–{{ day.closing | formatTime }}</span>
                        <span v-else>{{ $t("info.closed") }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
// import MenuComponent from '@/components/MenuComponent'
import PhotoCover from '@/components/PhotoCover'
import BaseGallery from '@/components/base/BaseGallery'

import gql from 'graphql-tag'
import { format } from 'date-fns'

const getCafe = gql `
    query Cafe {
        cafe {
            id
            name
            streetname
            city
            zipcode
            country_no
            country_en
            content_no
            content_en
            contentShort_no
            contentShort_en
            facebook
            instagram
            tripadvisor
            mail
            openinghours {
                day_en
                day_no
                opening
                closing
            }
            photo_cover {
                caption
                formats
            }
            photo_gallery {
                caption
                formats
            }
            foodmenu {
                sectiontitle_en
                sectiontitle_no
                menu_item {
                    title_en
                    title_no
                    additional_info_en
                    additional_info_no
                    price_no
                }
            }
            drinks {
                sectiontitle_en
                sectiontitle_no
                menu_item {
                    title_en
                    title_no
                    additional_info_en
                    additional_info_no
                    price_no
                }
            }
        }
    }
`;

export default {
    apollo: {
        cafe: {
            query: getCafe
        }
    },
    components: {
        MarkedParser,
        // MenuComponent,
        PhotoCover,
        BaseGallery
    },
    props: {
        menuContent: {
            type: Array,
            default: () => ([])
        },
        photoCover: {
            type: String,
            default: '',
        },
        galleryContent: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            ogDesc: '',
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    metaInfo() {
        return {
            title: this.$t("nav.cafe"),
            meta: [
                { property: 'og:title', content: 'Kafé Nitja', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: '/nsfsKafe-openGraph.jpg', vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: '/nsfsKafe-openGraph.jpg', vmid: 'twitter:card' },
                { property: 'twitter:title', content: 'Kafé Nitja', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        }
    },
    updated() {
        let pageDescription = this.cafe.contentShort_no;
        this.ogDesc = pageDescription
    },
    methods: {
        reverseMessage: function(value) {
            const mail = value.split('').reverse().join('')
            return mail
        }
    }
}
</script>