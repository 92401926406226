<template>
    <div id="teammembers" class="teamcards">
        <div class="teamcard--left">
            <img v-if="cardContent.photo_portrait" :src="api_url + cardContent.photo_portrait.formats.thumbnail.url" :alt="cardContent.name + ' portrait'">
            <div v-else class="portrait-placeholder">
                <img src="../../assets/images/nitja-corelogo-500px.svg" alt="Nitja senter for samtidskunst logo">
            </div>
        </div>
    
        <div class="teamcard--right">
            <div id="details">
                <p id="name">
                    {{ cardContent.first_name }} {{ cardContent.last_name }}
                </p>
                <p v-if="$i18n.locale === 'en'" class="metatext">
                    {{ cardContent.function_en }}
                </p>
                <p v-else class="metatext">
                    {{ cardContent.function_no }}
                </p>
            </div>
            <div id="contact" class="metatext">
                <p v-if="cardContent.primary_phone">
                    <span>T</span><span>{{ cardContent.primary_phone }}</span>
                </p>
                <p v-if="cardContent.secondary_phone">
                    <span>M</span><span>{{ cardContent.secondary_phone }}</span>
                </p>
                <p v-if="cardContent.mail">
                    <span>E</span>
                    <span>
                <a
                  class="reversed"
                  :href="`mailto:${cardContent.mail}`"
                >{{ reverseMessage(cardContent.mail) }}</a>
              </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardContent: {
          type: Object,
          default: () => ({})
        }
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        };
    },
    methods: {
        reverseMessage: function(mail) {
            const mailR = mail.split('').reverse().join('')
            return mailR
        },
    }
}
</script>

<style lang="scss" scoped>

</style>