<template>
  <section v-if="pilotgalleriet" id="single-page">
    <div class="defaultcontent-grid">
      <PhotoCover :photo-cover="pilotgalleriet.photo_cover" />

      <div class="singlepage-grid-primary">
        <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="pilotgalleriet.content_no" class="markdown" />
        <MarkedParser v-else :marked-content="pilotgalleriet.content_en" class="markdown" />
      </div>

      <div class="singlepage-grid-secondary">
        <ul class="logos">
          <li v-for="file in pilotgalleriet.logos" :key="file.name" class="logo">
            <a v-if="file.caption" :href="file.caption | cleanUrl" target="_blank" rel="noreferrer noopener"><img
                :src="api_url + file.url" :alt="file.name"></a>
            <img v-else :src="api_url + file.url" :alt="file.name">
          </li>
        </ul>
      </div>

      <div v-if="pilotgalleriet.light_activities.length > 0" id="activities"
        class="defaultcontent-grid--fullwidth listcomponent-order">
        <h1 class="section-header">
          {{ $t('pilotgalleriet.activities') }}
        </h1>
        <div id="collection">
          <!-- <div v-for="activity in allActivities" :key="activity.id">
            {{activity}}
          </div> -->
          <ListComponent :list-content="pilotgalleriet.light_activities" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import MarkedParser from '@/components/MarkedParser'
  import PhotoCover from '@/components/PhotoCover.vue'
  import ListComponent from '@/components/ListComponent.vue'
  import gql from 'graphql-tag';

  // const sortJson = require('sort-json');


  
  const getPilotgalleriet = gql ` query Pilotgalleriet {
    pilotgalleriet {
        content_no
        content_en
        contentShort_no
        contentShort_en
        photo_cover {
            caption
            formats
        }
        logos {
            caption
            formats
            url
            name
        }
        light_activities {
            title_en
            title_no
            type_en
            type_no
            schoolyear
            date
            contentShort_en
            contentShort_no
            related_exhibition {
                title
                slug
            }
            thumbnail {
                formats
            }
        }
    }
}`;
  export default {
    apollo: {
      pilotgalleriet: {
        query: getPilotgalleriet
      }
    },
    components: {
      MarkedParser,
      PhotoCover,
      ListComponent
    },
    props: {
      photoCover: {
        type: Object,
        default: () => ({}),
      },
      listContent: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        ogDesc: '',
        allActivities: '',
        api_url: process.env.VUE_APP_STRAPI_API_URL,
      }
    },
    computed: {

      // allActivities() {
      //   const allActivities = this.pilotgalleriet.light_activities
      //   console.log(allActivities)
      //   return allActivities
      // },
      // removeDuplicate(array) {
      //   console.log(array)
      //   let uniqueData = [...new Set(array)];
      //   console.log(uniqueData);
      //   return uniqueData
      // }
    },
    updated() {
      // let pageDescription = this.pilotgalleriet.contentShort_no;
      // this.ogDesc = pageDescription

      // const allActivities = this.pilotgalleriet.light_activities
      // console.log(allActivities)
      // this.allActivities = allActivities
      // console.log(allActivities);
      // let uniqueData = [...new Set(allActivities)];
      // console.log(uniqueData);
      // console.log(this.pilotgalleriet.light_activities)
      // const options = { ignoreCase: true, depth: 2};

      // const copy = sortJson(this.pilotgalleriet.light_activities, options);
      // console.log(copy)
      // return copy
    },
    mounted() {

    
    },
    metaInfo() {
      return {
        title: this.$t("nav.pilotgalleriet"),
        meta: [{
            property: 'og:title',
            content: 'Nitja Pilotgalleriet',
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content: this.ogDesc,
            vmid: 'og:description'
          },
          {
            property: 'og:image',
            content: '/nsfsPG-openGraph.jpg',
            vmid: 'og:image'
          },
          {
            property: 'og:type',
            content: 'article',
            vmid: 'og:type'
          },
          {
            property: 'article:publisher',
            content: 'https://www.nitja.no/',
            vmid: 'article:publisher'
          },
          {
            property: 'twitter:card',
            content: '/nsfsPG-openGraph.jpg',
            vmid: 'twitter:card'
          },
          {
            property: 'twitter:title',
            content: 'Nitja Pilotgalleriet',
            vmid: 'twitter:title'
          },
          {
            property: 'twitter:description',
            content: this.ogDesc,
            vmid: 'twitter:description'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  $pilotgallerietColor: #0031FF;

  ::v-deep .bar,
  ::v-deep .bar:first-of-type {
    border-color: $pilotgallerietColor;
  }

  ::v-deep .arrow {
    &:hover {
      border-color: $pilotgallerietColor;
    }
  }

  ::v-deep .title-hover:hover>span,
  ::v-deep .markdown a:hover {
    color: $pilotgallerietColor;
  }
</style>