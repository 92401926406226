<template>
    <div v-if="pageContent" class="defaultcontent-grid">
        <BaseNotificationMessage v-if="pageContent && checkEN && $i18n.locale === 'en'" :static-message-e-n="staticMessageEN" :message-type="messageType" />
    
        <PhotoCover v-if="pageContent.photo_cover" :photo-cover="pageContent.photo_cover" />
        <BaseGallery v-else-if="pageContent.photo_gallery && pageContent.photo_gallery.length > 0" :gallery-content="pageContent.photo_gallery" />
    
        <div class="singlepage-grid-primary">
            <p v-if="pageContent.content_no === '' || pageContent.content_no === null && pageContent.content_en === '' || pageContent.content_en === null" class="page-soon">
                {{ $t('headers.pageSoon') }}
            </p>
            <MarkedParser v-else-if="$i18n.locale === 'nb' || checkEN == true" :marked-content="pageContent.content_no" class="markdown" />
            <MarkedParser v-else :marked-content="pageContent.content_en" class="markdown" />
    
            <div class="contact-card-grid">
                <BaseTeamCard v-for="person in pageContent.press_contacts" :key="person.id" :card-content="person" />
            </div>
    
            <div v-if="pageContent.boardmembers" class="simple-twocolumn-list">
                <ul id="boardmembers">
                    <li>
                        <h1 v-if="pageContent.boardmembers" class="section-header">
                            {{ $t('activities.board') }}
                        </h1>
                    </li>
                    <li v-for="person in pageContent.boardmembers" :key="person.name">
                        <p class="title-default">
                            {{ person.first_name }} {{ person.last_name }}
                        </p>
                        <span v-if="$i18n.locale == 'nb'">{{ person.function_no }}</span>
                        <span v-else>{{ person.function_en }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import MarkedParser from '../MarkedParser'
import PhotoCover from '@/components/PhotoCover'
import BaseGallery from '@/components/base/BaseGallery'
import BaseNotificationMessage from '@/components/base/BaseNotificationMessage'
import BaseTeamCard from '@/components/base/BaseTeamCard'


export default {
    components: {
        MarkedParser,
        PhotoCover,
        BaseGallery,
        BaseNotificationMessage,
        BaseTeamCard
    },
    props: {
        pageContent: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            staticMessageNO: 'Denne siden er ikke tilgjengelig på engelsk',
            staticMessageEN: 'This page is not available in English',
            messageType: 'error',
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    computed: {
        checkEN: function() {
            let content = this.pageContent;
            let textEN = content.content_en
            if (textEN === '' || textEN === null) {
                return true
            }
            return false
        }
    }
}
</script>