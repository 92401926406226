<template>
  <div
    id="notification-message"
    class="message-bar messageslide-up bar-top"
    :class="{'message--error': messageType == 'error', 'message--notification' : messageType == 'notification'}"
  >
    <div class="bar--msg">
      <p v-if="$i18n.locale === 'en'">
        {{ staticMessageEN }}
      </p>
      <p v-else>
        {{ staticMessageNO }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        staticMessageNO: {
          type: String,
          default: ''
        },
        staticMessageEN: {
          type: String,
          default: ''
        },
        messageType: {
          type: String,
          default: ''
        }
    },
    mounted() {
        function messageBarInit() {
            const messageBar = document.getElementById("notification-message");
            messageBar.classList.remove('messageslide-up');
            messageBar.classList.add('messageslide-down');
            messageBar.addEventListener('click', function() {
                messageBar.classList.remove('messageslide-down');
                messageBar.classList.add('messageslide-up');
                messageBar.ontransitionend = () => {
                    messageBar.style.display = "none";
                };
            })
        }
        setTimeout(messageBarInit, 750);
    }
}
</script>