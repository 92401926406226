<template>
  <section
    v-if="nitjaKid"
    id="single-page"
  >
    <BaseBasicPage :page-content="nitjaKid" />
  </section>
</template>

<script>
import BaseBasicPage from '../components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        nitjaKid: {
            query: gql `
            query NitjaKids {
                nitjaKid {
                  content_en
                  content_no
                  photo_cover {
                      formats
                      caption
                  }
                }
            }
        `
        }
    },
    components: {
        BaseBasicPage
    },
    data() {
        return {
            ogDesc: '',
        };
    },
    metaInfo() {
        return {
            title: this.$t("nav.nitjaKids"),
            meta: [
                { property: 'og:title', content: 'Nitja Kids', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: '/nsfsKids-openGraph.jpg', vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: '/nsfsKids-openGraph.jpg', vmid: 'twitter:card' },
                { property: 'twitter:title', content: 'Nitja Kids', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    updated() {
        let pageDescription = this.nitjaKid.contentShort_no;
        this.ogDesc = pageDescription 
    }
}
</script>

<style lang="scss" scoped>
$kidsColor: #9465D3;
::v-deep .bar,
::v-deep .bar:first-of-type {
    border-color: $kidsColor;
}

::v-deep .arrow {
    &:hover {
        border-color: $kidsColor;
    }
}

::v-deep .title-hover:hover>span,
::v-deep .markdown a:hover {
    color: $kidsColor;
}
</style>