<template>
  <section
    v-if="nitjaFriend"
    id="single-page"
  >
    <BaseBasicPage :page-content="nitjaFriend" />

    <div
      v-if="activities"
      id="activities-overview"
    >
      <div id="friends-list">
        <h1 class="section-header">
          {{ $t('activities.friends') }}
          <span><router-link
            :to="{ path: '/activities' }"
            class="square-btn--default"
          >{{ $t("buttons.otherActivities") }}</router-link></span>
        </h1>
        <ul
          v-if="activities.length > 0"
          class="activity-grid--horizontal"
        >
          <li
            v-for="activity in sortActivities"
            :key="activity.id"
            class="activity-block"
          >
            <ActivityBlock :activity-content="activity" />
          </li>
        </ul>
        <ul v-else class="activity-grid--horizontal">
          <li class="event-grey-out">
            <span>{{ $t('activities.noevents') }}</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import ActivityBlock from '../components/activities/ActivityBlock'
import BaseBasicPage from '../components/base/BaseBasicPage'
import gql from 'graphql-tag'
import { add } from "date-fns"

const getNitjaFriends = gql `
    query NitjaFriends {
        nitjaFriend {
            content_en
            content_no
            photo_cover {
                formats
                caption
            }
            boardmembers {
                first_name
                last_name
                function_no
                function_en
            }
        }
    }
`
const getActivities = gql `
    query Activities($activityDay: String!) {
        activities: activities(limit: -1, where: {activity_category: {category_no: "Nitja Venn"}, date_gt: $activityDay} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_en
            contentShort_no
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                date
                time_start
                time_end
                url
            }
        }
   }
`;

export default {
    apollo: {
        nitjaFriend: {
            query: getNitjaFriends
        },
        activities: {
            query: getActivities,
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        }
    },
    components: {
        ActivityBlock,
        BaseBasicPage
    },
    data() {
        return {
            activityDay: '',
        }
    },
    metaInfo() {
        return {
            title: this.$t("nav.nitjaFriends"),
        }
    },
    computed: {
        sortActivities() {
            let activities = this.activities
            let today = new Date();
            let yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1);

            activities = activities.map((e) => {
                function filterDate(item) {
                    let thisDate = new Date(item.date)
                    if (thisDate > yesterday) {
                        return true
                    }
                    return false;
                }
                let result = e.planned_events.filter(filterDate)
                e.planned_events = result
                return e
            })
            let sorted = activities.sort((a, b) => {
                return (new Date(a.planned_events[0].date) - new Date(b.planned_events[0].date));
            }
            )
            return sorted;
        }
    },
    created() {
        this.getActivityDay()
    },
    methods: {
        getActivityDay() {
            let date = new Date();
            let datePlusDay = add(date, {days: -1}) 
            this.activityDay = datePlusDay
        }
    }
}
</script>