<template>
  <section v-if="kunstvisitten" id="single-page">
    <div v-if="kunstvisitten" class="defaultcontent-grid">
      <PhotoCover :photo-cover="kunstvisitten.photo_cover" />

      <div class="singlepage-grid-primary">
        <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="kunstvisitten.content_no" class="markdown" />
        <MarkedParser v-else :marked-content="kunstvisitten.content_en" class="markdown" />
      </div>

      <div class="singlepage-grid-secondary">
        <ul class="logos">
          <li v-for="file in kunstvisitten.logos" :key="file.name" class="logo">
            <a v-if="file.caption" :href="file.caption | cleanUrl" target="_blank" rel="noreferrer noopener"><img
                :src="api_url + file.url" :alt="file.name"></a>
            <img v-else :src="api_url + file.url" :alt="file.name">
          </li>
        </ul>
      </div>

      <div v-if="kunstvisitten.light_activities.length > 0" id="activities"
        class="defaultcontent-grid--fullwidth listcomponent-order">
        <h1 class="section-header">
          {{ $t('kunstvisitten.activities') }}
        </h1>
        <div id="collection">
          <ListComponent :list-content="kunstvisitten.light_activities" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import MarkedParser from '../components/MarkedParser'
  import PhotoCover from '../components/PhotoCover'
  import ListComponent from '../components/ListComponent'
  import gql from 'graphql-tag';

  const getKunstvisitten = gql ` query Kunstvisitten {
    kunstvisitten {
        content_no
        content_en
        contentShort_no
        contentShort_en
        photo_cover {
            caption
            formats
        }
        logos {
            caption
            formats
            url
            name
        }
        light_activities {
            title_en
            title_no
            type_en
            type_no
            date
            contentShort_en
            contentShort_no
            related_exhibition {
                title
                slug
            }
            thumbnail {
                formats
            }
        }
    }
}`;

  export default {
    apollo: {
      kunstvisitten: {
        query: getKunstvisitten
      }
    },
    components: {
      MarkedParser,
      PhotoCover,
      ListComponent
    },
    props: {
      photoCover: {
        type: Object,
        default: () => ({}),
      },
      listContent: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        ogDesc: '',
        api_url: process.env.VUE_APP_STRAPI_API_URL,
      }
    },
    metaInfo() {
      return {
        title: this.$t("nav.kunstvisitten"),
        meta: [{
            property: 'og:title',
            content: 'Nitja Kunstvisitten',
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content: this.ogDesc,
            vmid: 'og:description'
          },
          {
            property: 'og:image',
            content: '/nsfsKV-openGraph.jpg',
            vmid: 'og:image'
          },
          {
            property: 'og:type',
            content: 'article',
            vmid: 'og:type'
          },
          {
            property: 'article:publisher',
            content: 'https://www.nitja.no/',
            vmid: 'article:publisher'
          },
          {
            property: 'twitter:card',
            content: '/nsfsKV-openGraph.jpg',
            vmid: 'twitter:card'
          },
          {
            property: 'twitter:title',
            content: 'Nitja Kunstvisitten',
            vmid: 'twitter:title'
          },
          {
            property: 'twitter:description',
            content: this.ogDesc,
            vmid: 'twitter:description'
          },
        ]
      }
    },
    updated() {
      let pageDescription = this.kunstvisitten.contentShort_no;
      this.ogDesc = pageDescription
    }
  }
</script>

<style lang="scss" scoped>
  $kunstvisittenColor: #E8731F;

  ::v-deep .bar,
  ::v-deep .bar:first-of-type {
    border-color: $kunstvisittenColor;
  }

  ::v-deep .arrow {
    &:hover {
      border-color: $kunstvisittenColor;
    }
  }

  ::v-deep .title-hover:hover>span,
  ::v-deep .markdown a:hover {
    color: $kunstvisittenColor;
  }
</style>