<template>
    <section v-if="lounge" id="single-page">
        <BaseBasicPage :page-content="lounge" />
    
        <h1 v-if="lounge.lounge_exhibitions && $i18n.locale === 'nb'" class="section-header">
            {{ lounge.lounge_subheader_no }}
        </h1>
        <h1 v-else-if="lounge.lounge_exhibitions" class="section-header">
            {{ lounge.lounge_subheader_en }}
        </h1>
    
        <ImageGrid :grid-content="lounge.lounge_exhibitions" />
    </section>
</template>

<script>
import BaseBasicPage from '../components/base/BaseBasicPage'
import ImageGrid from '../components/ImageGrid'
import gql from 'graphql-tag'

export default {
    apollo: {
        lounge: {
            query: gql `
            query Lounge {
                lounge {
                    content_no
                    content_en
                    contentShort_no
                    contentShort_en
                    photo_cover {
                        formats
                        caption
                    }
                    lounge_subheader_no
                    lounge_subheader_en
                    lounge_exhibitions {
                        title_no
                        title_en
                        contentShort_no
                        contentShort_en
                        smallex_date_start    
                        smallex_date_end
                        thumbnail {
                            formats
                            caption
                        }
                        photo_gallery {
                            formats
                            caption
                        }
                    }
                }
            }
        `
        }
    },
    components: {
        BaseBasicPage,
        ImageGrid
    },
    data() {
        return {
            ogDesc: '',
            ogImage: '',
            ogTitle: this.$t("nav.lounge"),
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    metaInfo() {
        return {
            title: this.$t("nav.lounge"),
            meta: [
                { property: 'og:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: this.api_url + this.ogImage, vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: this.api_url + this.ogImage, vmid: 'twitter:card' },
                { property: 'twitter:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    updated() {
        let pageDescription = this.lounge.contentShort_no;
        let pageCover = this.lounge.photo_cover.formats.medium.url;
        this.ogDesc = pageDescription
        this.ogImage = pageCover
    }
}
</script>